<template>
  <v-container v-if="!loading" class="OrderSucc">
    <DialogSuccess class="wra-containt-dialog" v-if="getOrderResponse" :color="infoData.qrThemeColor" :orderResponse="getOrderResponse" />
  </v-container>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import DialogSuccess from "@/components/DialogSuccess";
export default {
  name: "OrderSuccess",
  components: {
    DialogSuccess,
  },
  data() {
    return {
        loading:true,
    };
  },
  computed: {
    ...mapGetters([
      "infoData",
      "get_pathQr",
      "CarShop",
      "dataClientOrder",
      "getOrderResponse",
    ]),
  },
  mounted() {
    if (this.getOrderResponse) {
        this.loading = false
    }
  },
};
</script>
<style scoped>
.wra-containt-dialog{
    max-width: 400px;
}
.OrderSucc{
    height:80vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>